import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Link from 'components/link';
import ExpListingCard from 'components/exp-listing-card';
import { useIsMobile, useIsTablet } from 'hooks/use-size-class';
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import CarouselArrows from './arrows';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

import type { ListingCardData } from 'components/listing-card';

export interface Props {
  isCrawler: boolean;
  availableHomeListings: ListingCardData[];
  availableHomeListingsMeta: number;
  availableHomeListingsLocation: string;
  isExpandedArea?: boolean;
  homesForSaleUrl: string;
  mapViewUrl: string;
}

const ANIMATION_DURATION = 300;

const mobileAnimation = { duration: 50000, easing: (t: number) => t };

export default function ExpForSaleSection({ isCrawler, availableHomeListings, availableHomeListingsMeta, availableHomeListingsLocation, isExpandedArea, homesForSaleUrl, mapViewUrl }: Props) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const scrollerContainerRef = useRef(null);

  const pauseAnimation = (animation: gsap.core.Tween) => animation.pause();
  const resumeAnimation = (animation: gsap.core.Tween) => animation.resume();

  const horizontalScroll = (event: WheelEvent, animation: gsap.core.Tween, width: number) => {
    if (Math.abs(event.deltaX) > 0) {
      event.preventDefault(); // Prevent the default scroll behavior
      const progressUpdate = event.deltaX / width; // Calculate the progress update based on scroll amount
      animation.progress(animation.progress() + progressUpdate); // Update the animation's progress
    }
  };

  const verticalScroll = (event: WheelEvent, animation: gsap.core.Tween, width: number) => {
    if (event.target && (event.target as HTMLElement).closest(`[data-testid="${testIds.modalInner}"]`)) {
      return; // Exit the function if the event target is within a modal
    }
    if (event.deltaY > 0) {
      const progressUpdate = event.deltaY / width; // Calculate the progress update based on scroll amount
      animation.progress(animation.progress() + progressUpdate);
    }
  };

  const carouselArrowClick = (slide: 'next' | 'prev', animation: gsap.core.Tween) => {
    const currentProgress = animation.progress();
    const progressOffset = slide === 'next' ? 0.06 : -0.06;
    gsap.to(animation, {
      progress: currentProgress + progressOffset,
      duration: 0.5,
      ease: 'power1.inOut',
    });
  };

  useEffect(() => {
    const scrollerContainer = scrollerContainerRef.current as unknown as HTMLDivElement;
    const width = scrollerContainer?.offsetWidth / 2;
    const animation = gsap.to(scrollerContainer, {
      x: -width,
      ease: 'none',
      repeat: -1,
      duration: ANIMATION_DURATION,
      modifiers: {
        x: gsap.utils.unitize(x => parseFloat(x) % width),
      },
    });

    // Clicking the carousel arrows should slide the cards
    const nextArrowElement = document.getElementById('nextArrow');
    const prevArrowElement = document.getElementById('prevArrow');
    nextArrowElement?.addEventListener('click', () => carouselArrowClick('next', animation));
    prevArrowElement?.addEventListener('click', () => carouselArrowClick('prev', animation));

    // Pause or resume automatic scroll based on hover
    const scrollerElement = document.getElementById('scroller');
    scrollerElement?.addEventListener('mouseenter', () => pauseAnimation(animation));
    scrollerElement?.addEventListener('mouseleave', () => resumeAnimation(animation));

    // Add event listener for horizontal scrolling via touchpad
    scrollerElement?.addEventListener('wheel', (event: WheelEvent) => horizontalScroll(event, animation, width));
    // Increase scroll speed when scrolling down
    window.addEventListener('wheel', (event: WheelEvent) => verticalScroll(event, animation, width));

    if (isMobile) {
      new KeenSlider(
        '#scroller',
        {
          loop: true,
          renderMode: 'performance',
          mode: 'free',
          slides: {
            perView: 1.2,
            spacing: 15,
          },
          created(s) {
            s.moveToIdx(5, true, mobileAnimation);
          },
          updated(s) {
            s.moveToIdx(s.track.details.abs + 5, true, mobileAnimation);
          },
          animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, mobileAnimation);
          },
        }
      );
    }

    return () => {
      nextArrowElement?.removeEventListener('click', () => carouselArrowClick('next', animation));
      prevArrowElement?.removeEventListener('click', () => carouselArrowClick('prev', animation));
      scrollerElement?.removeEventListener('mouseenter', () => pauseAnimation(animation));
      scrollerElement?.removeEventListener('mouseleave', () => resumeAnimation(animation));
      scrollerElement?.removeEventListener('wheel', (event: WheelEvent) => horizontalScroll(event, animation, width));
    };
  }, [isMobile]);

  return (
    <section className={styles.component}>
      <div className={styles.header}>
        <div className={styles.left}>
          <p className={styles.properties}>{availableHomeListingsMeta}+ Properties {isExpandedArea ? 'near' : 'in'}</p>
          <Link href={homesForSaleUrl}><h2 className={styles.location}>{availableHomeListingsLocation}</h2></Link>
        </div>
        {!isTablet &&
        <Link href={mapViewUrl}>
          <div className={styles.map}>
            <p>View Map</p>
          </div>
        </Link>
        }
      </div>
      <div className={styles['scroller-outer']} id="scroller-outer">
        <div id="scroller" className={buildClassName(isMobile && 'keen-slider', styles['scroller-inner'])} ref={scrollerContainerRef}>
          {[...availableHomeListings, ...availableHomeListings].map((listing, index) => {
            // Refrain from showcasing listings with images that are not ready yet
            if (listing?.isImageReady) {
              return (
                <div key={index} className={buildClassName(isMobile && 'keen-slider__slide', styles['card-wrapper'])}>
                  <ExpListingCard listing={listing} isCrawler={isCrawler} />
                </div>
              );
            }
          })}
        </div>
        {!isMobile &&
          <CarouselArrows className={styles.arrow} />
        }
      </div>
      {isTablet &&
      <div className={styles['mobile-view-map']}>
        <Link href={mapViewUrl}>
          <div className={styles.map}>
            <p>View Map</p>
          </div>
        </Link>
      </div>
      }
    </section>
  );
}
